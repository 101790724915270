<template>
  <el-dialog
    v-if="openDialog"
    :title="!!data.id?'编辑视频':'添加视频'"
    :visible.sync="!!data.id || openDialog"
    :width="isLook?'70%':'50%'"
    :class="isLook?'flexbox':''"
    :before-close="close"
  >
  <!-- 视频播放 -->
    <div class="video">
      <xg-player ref="player" :url="videoUrl" @triggerEvent="triggerEvent" ></xg-player>
      <!-- <video v-if="isLook" ref="videoPlayer" class="video-js"></video> -->
    </div>
    <!-- {{ delUrl }} -->
    <div  :class="isLook?'formboxs':'normalbox'">
      <!--视频管理表单  表单中注释的为老版本存在 但不知道字段-->
      <el-form
        :rules="rules"
        ref="form"
        :model="form"
        label-width="120px"
        :hide-required-asterisk="true"
        v-if="delUrl === '/api/video/destroy'"
        v-loading="dialogLoading"
      >
      <el-form-item label="UP主" prop="vlogger_id">
          <el-select v-model="form.vlogger_id" placeholder="请选择" :clearable="false">
            <el-option
              :label="item.account"
              :value="item.id"
              v-for="item in upList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      <el-form-item label="连载名称" prop="sets_id">
          <el-select v-model="form.sets_id" placeholder="请选择" :clearable="false">
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="item in parentsList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="连载集数" prop="episode">
          <el-input v-model.number="form.episode"></el-input>
        </el-form-item> 
        <el-form-item label="视频标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="帖子内容" prop="content">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="媒体类型">
          <el-select v-model="form.media_type" placeholder="请选择" :clearable="false">
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in mediaList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="打点数据" prop="episode">
          <el-input v-model.number="form.episode"></el-input>
        </el-form-item> -->
        <el-form-item label="标签">
          <div v-for="(item, index) in tagList" :key="index" class="tag-boxs">
            <template v-if="item.tags && item.tags.length">
              <h2>
                <i :class="!item.isFold?'el-icon-arrow-down':'el-icon-arrow-right'" @click="item.isFold=!item.isFold"></i>
                {{item.cat_name}}
              </h2>
              <el-collapse-transition>
                <template v-if="item.enable && !item.isFold">
                  <!-- 多选 -->
                  <el-checkbox-group v-if="item.is_multiple && row[index]" v-model="row[index].check">
                    <el-checkbox v-for="(itemtag, indextag) in item.tags" :key="indextag" :label="itemtag.id">{{itemtag.tag_name}}</el-checkbox>
                  </el-checkbox-group>
                  <!-- 单选 -->
                  <el-radio-group v-if="!item.is_multiple && row[index]" v-model="row[index].check">
                    <el-radio  v-for="(itemtag, indextag) in item.tags" :key="indextag" :label="itemtag.id">{{itemtag.tag_name}}</el-radio>
                  </el-radio-group>
                </template>
              </el-collapse-transition>
            </template>
            
          </div>

        </el-form-item>
        <el-form-item label="视频封面" prop="cover_image" class="uploadBox">
          <!-- <el-input v-model="form.cover_image"></el-input> -->
          <uploadImage
            :imageUrl="form.cover_image"
            @uploadFileUrl="uploadImg"
            @clearImage="clearImage"
            @validate="validateImage"
            :objKey="'cover_image'"
          ></uploadImage>
        </el-form-item>
        <el-form-item label="竖版封面">
          <!-- <el-input v-model="form.cover_image"></el-input> -->
          <uploadImage
            :imageUrl="form.vertical_cover"
            @uploadFileUrl="uploadImg"
            @clearImage="clearImage"
            @validate="validateImage"
            :objKey="'vertical_cover'"
          ></uploadImage>
        </el-form-item>
        <!-- <el-form-item label="视频动态封面"  prop="cover_gif">
          <uploadImage
            :imageUrl="form.cover_gif"
            @uploadFileUrl="uploadImg"
            @clearImage="clearImage"
            @validate="validateImage"
            :objKey="'cover_image'"
          ></uploadImage>
        </el-form-item> -->
        <el-form-item label="视频图册"  prop="pics">
          <uploadImage
            :imageUrl="form.pics"
            @uploadFileUrl="uploadImg"
            @clearImage="clearImage"
            @validate="validateImage"
            :objKey="'pics'"
            :multiple="true"
          ></uploadImage>
        </el-form-item>
        <!-- <el-form-item label="视频动图集"  prop="gifs">
          <uploadImage
            :imageUrl="form.gifs"
            @uploadFileUrl="uploadImg"
            @clearImage="clearImage"
            @validate="validateImage"
            :objKey="'gifs'"
          ></uploadImage>
        </el-form-item> -->
        <el-form-item label="480p地址" :prop="form.media_type==0?'src_480':''">
          <el-input v-model="form.src_480"></el-input>
        </el-form-item>
        <el-form-item label="720p地址">
          <el-input v-model="form.src_720"></el-input>
        </el-form-item>
        <el-form-item label="1080p地址">
          <el-input v-model="form.src_1080"></el-input>
        </el-form-item>
        <el-form-item label="视频预览地址"  :prop="form.media_type==0?'preview_src':''">
          <el-input  v-model="form.preview_src"></el-input>
        </el-form-item>
        <el-form-item label="质量打分"  prop="quality_score">
          <el-input  v-model.number="form.quality_score"></el-input>
        </el-form-item>
        <el-form-item label="是否精品">
          <el-switch v-model="form.is_high_quality"></el-switch>
        </el-form-item>
        <el-form-item label="是否竖版">
          <el-switch v-model="form.is_vertical"></el-switch>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择" :clearable="false">
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in statusList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.status === 7" label="弃用理由">
          <el-input v-model="form.refuse_reason"></el-input>
        </el-form-item>
      </el-form>
      <!-- 视频连载表单-->
      <el-form
        :rules="rules"
        ref="form"
        :model="form"
        label-width="120px"
        :hide-required-asterisk="true"
        v-if="delUrl === '/api/video/sets/destroy'"
        v-loading="dialogLoading"
      >
        <el-row :gutter="20" >
          <el-col :span="24">
            <el-form-item label="父类" prop="parent_id">
              <!-- <el-input v-model="form.parent_id"></el-input> -->
              <el-select v-model="form.parent_id"  filterable :clearable="false">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in parentsList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="标题" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="封面图" prop="cover_image">
              <uploadImage
                :imageUrl="form.cover_image"
                @uploadFileUrl="uploadImg"
                @clearImage="clearImage"
                @validate="validateImage"
                :objKey="'cover_image'"
              ></uploadImage>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="简介" prop="description">
              <el-input v-model="form.description" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总集数" prop="all_video_count">
              <el-input v-model.number="form.all_video_count"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序值" prop="score_count">
              <el-input v-model.number="form.score_count"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评分人数" prop="sort_order">
              <el-input-number v-model="form.sort_order"
                :min="0"
                :max="100"
                controls-position="right"
                ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评分" prop="score">
              <el-input-number v-model="form.score"
                :min="0"
                :max="100"
                controls-position="right"
                ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否完结">
              <el-switch v-model="form.is_over"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="请选择"  :clearable="false">
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in typeList"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.status === 7" label="弃用理由">
            <el-input v-model="form.refuse_reason"></el-input>
          </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--短视频表单-->
      <el-form
        :rules="rules"
        ref="form"
        :model="form"
        label-width="120px"
        v-if="delUrl === '/api/video/short/destroy'"
        :hide-required-asterisk="true"
        v-loading="dialogLoading"
      >
        <el-form-item label="主视频ID">
          <el-input disabled v-model="form.main_hash_id"></el-input>
        </el-form-item>
        <el-form-item label="UP主" prop="vlogger_id">
          <el-select v-model="form.vlogger_id" placeholder="请选择" :clearable="false">
            <el-option
              :label="item.account"
              :value="item.id"
              v-for="item in upList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="视频标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="视频封面" prop="cover_image">
          <!-- <el-input v-model="form.cover_image"></el-input> -->
          <uploadImage
            :imageUrl="form.cover_image"
            @uploadFileUrl="uploadImg"
            @clearImage="clearImage"
            @validate="validateImage"
            :objKey="'cover_image'"
          ></uploadImage>
        </el-form-item>
        <el-form-item label="标签">
          <div v-for="(item, index) in tagList" :key="index" class="tag-boxs">
            <template v-if="item.tags && item.tags.length">
              <h2>
                <i :class="!item.isFold?'el-icon-arrow-down':'el-icon-arrow-right'" @click="item.isFold=!item.isFold"></i>
                {{item.cat_name}}
              </h2>
              <el-collapse-transition>
                <template v-if="item.enable && !item.isFold">
                  <!-- 多选 -->
                  <el-checkbox-group v-if="item.is_multiple && row[index]" v-model="row[index].check">
                    <el-checkbox v-for="(itemtag, indextag) in item.tags" :key="indextag" :label="itemtag.id">{{itemtag.tag_name}}</el-checkbox>
                  </el-checkbox-group>
                  <!-- 单选 -->
                  <el-radio-group v-if="!item.is_multiple && row[index]" v-model="row[index].check">
                    <el-radio  v-for="(itemtag, indextag) in item.tags" :key="indextag" :label="itemtag.id">{{itemtag.tag_name}}</el-radio>
                  </el-radio-group>
                </template>
              </el-collapse-transition>
            </template>
            
          </div>

        </el-form-item>
        <!-- 老版本没有以下注释字段 -->
        <!-- <el-form-item label="视频长度">
          <el-input v-model.number="form.length"></el-input> -->
          <!-- <el-input-number
            v-model="form.length"
            controls-position="right"
            :min="1"
            :max="10"
          ></el-input-number> -->
        <!-- </el-form-item> -->
        <!-- <el-form-item label="1080P文件大小">
          <el-input v-model.number="form.file_size_1080"></el-input>
        </el-form-item>
        <el-form-item label="720P文件大小">
          <el-input v-model.number="form.file_size_720"></el-input>
        </el-form-item>
        <el-form-item label="480P文件大小">
          <el-input v-model.number="form.file_size_480"></el-input>
        </el-form-item> -->
        <el-form-item label="480p地址">
          <el-input disabled v-model="form.src_480"></el-input>
        </el-form-item>
        <el-form-item label="720p地址">
          <el-input disabled v-model="form.src_720"></el-input>
        </el-form-item>
        <el-form-item label="1080p地址">
          <el-input disabled v-model="form.src_1080"></el-input>
        </el-form-item>
        <el-form-item label="是否竖版视频">
          <el-switch v-model="form.is_vertical"></el-switch>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择"  :clearable="false">
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in typeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.status === 7" label="弃用理由">
          <el-input v-model="form.refuse_reason"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="submitForm('form')"
          v-loading="loading"
          >确 定</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
import uploadImage from "@/views/medium/components/uploadImage";
import API from "@/api";
// import 'video.js/dist/video-js.css'
// import videojs from "video.js"
// import "videojs-contrib-hls";
import config from "../../../utils/serverUrlConfig";
import local from "@/utils/localStroage.js";
import XgPlayer from './XgPlayer.vue';

export default {
  name: "videoDialog",
  props: ["data","parentsList","isLook"],
  components: { uploadImage,XgPlayer },
  data() {
    return {
      videoUrl:"",
      upList:[],
      openDialog: false,
      form: {},
      loading: false,
      delUrl: "",

      typeList: [
        {
          key: 0,
          value: "准备中",
        },
        {
          key: 7,
          value: "弃用",
        },
        {
          key: 10,
          value: "完成&可使用",
        },
      ],
      rules: {
        sets_id:[{ required: true, message: "请选择连载名称", trigger: "change" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        cover_image: [{ required: true, message: "请输入封面图", trigger: ["blur","change"] }],
        sort: [{ required: true, message: "请输入排序值", trigger: "blur" }],
        length: [{ required: true, message: "请输入下属视频数", trigger: "blur" }],
        episode: [{ required: true, message: "请输入总集数", trigger: "blur" }],
        data_version: [{ required: true, message: "请输入评分人数", trigger: "blur" }],
        quality_score: [{ required: true, message: "请输入质量打分", trigger: "blur" }],
        create_time: [{ required: true, message: "请输入创建时间", trigger: "blur" }],
        parent_id: [{ required: true, message: "请选择父类", trigger: "change" }],
        description: [{ required: true, message: "请输入简介", trigger: "blur" }],
        all_video_count: [{ required: true, message: "请输入总集数", trigger: "blur" }],
        score_count: [{ required: true, message: "请输入排序值", trigger: "blur" }],
        sort_order: [{ required: true, message: "请输入评分人数", trigger: "blur" }],
        score: [{ required: true, message: "请输入评分", trigger: "blur" }],
        tags: [{ required: true, message: "请输入标签", trigger: "blur" }],
        file_size_1080: [{ required: true, message: "请输入1080P文件大小", trigger: "blur" }],
        file_size_720: [{ required: true, message: "请输入720P文件大小", trigger: "blur" }],
        file_size_480: [{ required: true, message: "请输入480P文件大小", trigger: "blur" }],
        src_1080: [{ required: true, message: "请输入1080p地址", trigger: "blur" }],
        src_720: [{ required: true, message: "请输入720p地址", trigger: "blur" }],
        src_480: [{ required: true, message: "请输入480p地址", trigger: ["blur","change"] }],
        vertical_cover:[{ required: true, message: "请输入竖版封面", trigger: "blur" }],
        // cover_gif:[{ required: true, message: "请输入视频动态封面", trigger: "blur" }],
        preview_src:[{ required: true, message: "请输入视频预览地址", trigger: ["blur","change"] }],
        vlogger_id:[{ required: true, message: "请选择up主", trigger: "change" }]
      },
      // 视频连载管理父类
      statusList:[
        {
          key: 0,
          value: "准备中",
        },
        {
          key: 7,
          value: "弃用",
        },
        {
          key: 10,
          value: "完成&可使用",
        },
      ],
      // 媒体类型
      mediaList:[
        {
          key: 0,
          value: "视频",
        },
        {
          key: 1,
          value: "图文",
        }
      ],
      dialogLoading:false,
      tagList:[],
      row:[],
      options: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", //将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "application/x-mpegURL", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", //url地址
          },
        ],
        controls: true,
        poster: "", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      player:null
    };
  },
  watch: {
    data(data) {
      this.form = data;
    },
    // data:{
    //   handler: function(newData) {
    //     console.log(newData)
    //   },
    //     deep: true,
    //     immediate: true
    //   }
  },
  methods: {
    triggerEvent(value){
        console.log("是否播放：",value);
      },
    validate(callback){
      //这个ruleFormRef是子组件内部el-form 的ref="ruleFormRef"
      this.$refs.form.validate((valid) => {
        callback(valid);
      });
    },
    close() {
      if (this.$refs.player &&this.$refs.player.dPlayer ){
        this.$refs.player.dPlayer.destroy()

      }
      // if(this.player){
      //   this.player.dispose();
      // }
      this.$emit("clearData");
    },
    uploadImg(key, value,multiple) {
      if(multiple){
        let str = this.form[key]+','+value;
        this.$set(this.form,key,str)

      }else{
        this.$set(this.form,key,value)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          
          if(this.delUrl !== '/api/video/sets/destroy'){
            // // 提交的时候处理标签选中数据
            let arr = []
            this.row.forEach(v=>{
              if(v.type === 'check' && v.check.length){
                arr = arr.concat(v.check)
              }else if(v.type === 'radio' && v.check){
                arr.push(v.check)
              }
            })
            this.form.tags = arr.join(",")
            if(!this.form.tags){
              this.$message({
                message: '请选择标签',
                type: 'warning'
              });
              return false
            }
          }
          this.$emit('pushData', this.form) 
          this.loading = true
        } else {
          return false;
        }
      });
    },
    clearImage(key,data,multiple){
      if(multiple){
        this.form[key]=data
      }else{
        this.form[key]=''
      }
    },
    // 获取标签树 以及 up主
    getTargetTree(){
      this.row = []
      this.dialogLoading = true
      let apiOne = API.getTagTree({is_up:false});
      let apiTwo = API.getUpListData({page:1,size:9999})
      Promise.all([apiOne,apiTwo]).then((res)=>{
        console.log(res)
        // 标签数据
        if(!res[0].code){
          // 方便标签选中
          res[0].data.forEach(v=>{
            let list = []
            // if(v.tags.length){
              v.tags.forEach(x=>{list.push(x.id)})
              if(v.is_multiple){
                this.row.push({check:[],type:'check',list:list,input:"",father:v.id})
              }else if(!v.is_multiple){
                this.row.push({check:"",type:'radio',list:list})
              }
            // }
          })
          this.tagList = res[0].data
          console.log(this.row)
          // 编辑数据回显 
          let value = this.form.tags.split(",")
          this.row.forEach(v=>{
            if(v.list.length){
              v.list.forEach(x=>{
                if(value.includes(x.toString())){
                  v.type==='check' ? v.check.push(x) : v.check=x
                }
              })
            }
          })
        }else{
          this.tagList = []
        }
        // up主数据
        if(!res[1].code){
          this.upList = res[1].data.data
        }else{
          this.upList = []
        }
        this.dialogLoading = false
      })
      // API.getTagTree().then((res)=>{
      //   if(!res.code){
      //     // 方便标签选中
      //     res.data.forEach(v=>{
      //       let list = []
      //       if(v.tags.length){
      //         v.tags.forEach(x=>{list.push(x.id)})
      //         v.is_multiple ? this.row.push({check:[],type:'check',list:list,input:"",father:v.id}) : this.row.push({check:"",type:'radio',list:list})
      //       }
      //     })
      //     this.tagList = res.data
      //     // 编辑数据回显 
      //     let value = this.form.tags.split(",")
      //     this.row.forEach(v=>{
      //       if(v.list.length){
      //         v.list.forEach(x=>{
      //           if(value.includes(x.toString())){
      //             v.type==='check' ? v.check.push(x) : v.check=x
      //           }
      //         })
      //       }
      //     })
      //     this.dialogLoading = false
      //   }else{
      //     this.dialogLoading = false
      //   }
      // })

    },
    validateImage(value,imagesUrl){
       this.$set(this.form,value,imagesUrl)
    },
    loadVideo(data) {
      this.$nextTick(() => {
        // console.log(config.baseServerUrl)
        this.videoUrl = `${config.baseServerUrl}/api/m3u8/${data}?token=${local.get('userInfo').token}`
        // console.log(this.options.sources[0].src)
        // this.player = videojs(this.$refs.videoPlayer,this.options,function onPlayerReady() {
        // })
      });
    },
  },
};
</script>
<style>
textarea {
  font-family: Arial, Helvetica, sans-serif;
}
</style>

<style scoped lang="scss">
.el-dialog ::v-deep .el-select{
  width: 100% !important;
}
.uploadBox ::v-deep .el-form-item__content{
  // display: flex;
}
.el-form-item{
  margin-bottom: 22px;
}
// .tag-boxs{
//   display: flex;
// }
.el-checkbox,.el-radio{
  margin-right: 20px;
}
h2{
  font-size: 14px;
}
.video{
  width: 550px;
}
.flexbox ::v-deep .el-dialog__body{
  display: flex;
  justify-content: space-between;
}
.formboxs{
  width: calc(100% - 500px);
  max-height: 630px;
  overflow-y: auto;
}
.normalbox{
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .video-js .vjs-time-control{
  display: inline-block !important;
  padding: 0 2px !important;
  text-align: center;
}
</style>